import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import imgHero from "../images/gfx/hero-article.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-2.jpg"

// import imgProfileKevinMortlock from "../images/profile-kevin-mortlock.jpg"
import imgCaseStudy from "../images/flagfinders-case-study-team-wide.jpg"
import imgVehicles from "../images/flagfinders-case-study.jpg"
import imgStudentQRCode from "../images/student-coach-qr-code.jpg"
import "./index.css";
import "./case-study.css";
import Nav from "../components/nav"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from "../components/contact"
import Footer from "../components/footer"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Case study: How Flagfinders regained control of its admin and modernised its operation with ShuttleID" />
        <Nav activeTab="case-studies" />
        <div>
            <div>

              <div className="case-study">

                <div className="hero-article" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                  <h1 className="blog-headline">From termly toil to complete control: Flagfinders’ home-to-school travel transformation</h1>
                </div>

                <div className="mobile-hero-article" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                  <h1 className="blog-headline">From termly toil to complete control: Flagfinders’ home-to-school travel transformation</h1>
                </div>

                <div className="article">
                  <div className='article-intro article-inner'>
                    <p>This successful family-run coach company revolutionised its admin and mastered its payments and passenger numbers, saving two days' work a week.</p>
                  </div>
                  <div className='article-inner'>
                    <div className='case-study__metrics'>
                      <div className='case-study__metric'>
                        <span className='case-study__metric-val'>40%</span>
                        weekly admin saved
                      </div>
                      <div className='case-study__metric'>
                        <span className='case-study__metric-val'>190,000</span>
                        journeys protected annually
                      </div>
                    </div>
                    <div className='case-study__about'>
                      <h3>About Flagfinders Coach Hire</h3>
                      <p><a href="http://www.flagfinders.com/">Flagfinders</a> is a family-run coach company based in Braintree in Essex. Established in 1976, it operates a well-maintained fleet and a wide range of transport solutions, including home-to-school travel.</p>
                    </div>

                    <h2>Pride of Essex</h2>
                    <p>Flagfinders is a company that prides itself on things running smoothly. With a suite of workshops to keep its fleet in tip-top condition, Flagfinders delivers a reliable service and fantastic safety record. It’s little wonder the business is in high demand.</p>
                    <p>
                      <img style={{ marginBottom: 0 }} src={imgCaseStudy} alt="Flagfinders team" />
                      <span><em>The Flagfinders team: Wendy, Lesley and Trish</em></span>
                    </p>

                    {/* <div className='case-study__quote'>
                      <p>"It was quite a nightmare, really."</p>
                      <p className='case-study__quote-author'>&mdash; Kevin Mortlock, Managing Director, Keane Travel Ltd</p>
                    </div> */}

                    <h2>A bus pass system caught in the slow lane</h2>
                    <p>Whilst the business was a runaway success, Flagfinders’ school bus admin was one area struggling to stay up to speed.</p>
                    <p>As their home-to-school transport services grew, admin duo Wendy and Trish saw increasingly large chunks of their time (and paper!) consumed. The trail would begin when parents were issued with new bus pass application forms. Payment would be made by BACS or card payments taken over the phone. One-by-one, the forms and payments had to be matched and compiled onto a spreadsheet before passes could be printed and distributed. The constant calls and emails left little time for any other work, as Flagfinders’ director  Lesley Cook-Leyden witnessed.</p>
                    <p>“It was such hard work,” said Lesley.</p>
                    <p>To use their time effectively, Wendy and Trish printed bus passes termly.  But with infrequent use, the printing machine often didn’t work, making it a laborious task.</p>


                    <h2>Pulling a fast one</h2>
                    <p>Once printed, bus passes were distributed via schools. But parents would ring Flagfinders, advising they hadn’t received theirs. Whilst a new pass could be issued, there was no way to retrieve or cancel the original, leaving every possibility that somebody somewhere was getting a free ride.</p>

                    <h2>In pursuit of payment</h2>
                    <p>Keeping track of payments proved difficult too. Between them, Wendy and Trish would check incoming payments against their spreadsheets. Wendy explained, “A lot of parents made one-off payments instead of setting up standing orders, so when the first regular payment was due, we were forever phoning and leaving messages to remind them to make the payment. Every month we could spend a couple of days just chasing debts.”</p>
                    <p><img src={imgVehicles} alt="Flagfinders fleet" /></p>

                    <h2>Time to regain control</h2>
                    <p>With 500+ passengers and the new academic year approaching, Flagfinders saw another mountain of work looming. In addition, Lesley wanted a better grasp on who was travelling on each service. She knew her drivers couldn’t memorise every pass as children boarded, or indeed catch students handing passes out through the windows to their friends. With the added problems of navigating Covid lockdowns, Lesley was ready to try something different.</p>
                    <p>“We had to start working smarter, not harder,”  said Lesley.</p>

                    <h2>More organised</h2>
                    <p>Lesley contacted ShuttleID and an online demo was arranged quickly with ShuttleID director, Emma Bell.</p>
                    <p>Emma explained how Flagfinders’ home-to-school travel could become more organised and structured, helping them to regain control and reduce costly administration time and effort.</p>
                    <p>It was soon clear that ShuttleID could make a huge difference almost overnight. With Emma and husband Chris at the end of the phone, Flagfinders soon had all their passes listed for sale online. Using both the card and direct debit options meant that the transition was super-easy for parents too. In fact, parents could now purchase passes 24/7, whether they were at home or on their lunch break – and all without having to contact Flagfinders or the school.</p>

                    <h2>Working smarter</h2>
                    <p>Initially, parents questioned how long the ticket QR codes would take to come through. But they were happily surprised to see them land in their email instantly  after purchase, ready for sharing to their child’s phone for immediate use.</p>
                    <p>Wendy and Trish, who both have experience in the banking sector, were thrilled by how much information the system provides, especially with regards to payments. They now have a complete handle on the direct debits and, conveniently, any failed payments are flagged.</p>
                    <p>What’s interesting is Flagfinders saw parents respond better to the direct debit ticket option. Wendy said, “Rather than constantly having to chase payments, we found only a few direct debits unpaid – but for genuine reasons. We've got way more control.” </p>

                    <p><img src={imgStudentQRCode} alt="Student using QR code" /></p>

                    <h2>Better for drivers</h2>
                    <p>Drivers have since reported that scanning the ShuttleID QR Codes means the boarding process stays more orderly, as children no longer rush on to the bus together. Wendy said, “The students look after their phones a lot better than they looked after their passes, but the option to print the pass is a really good backup.”</p>

                    <h2>Another satisfied customer</h2>
                    <p>When thinking back to previous years, during July and August, the Flagfinders team would be on the phone constantly dealing with passes. Now with ShuttleID, they estimate they are saving a couple of days a week easily.</p>
                    <p>Lesley and her team are ready for when the schools return each September and the fear of parents phoning up for last-minute tickets has gone. The team simply emails a ticket link, and a student can travel the same day. Lesley is completely assured of the number of tickets in circulation, and the school is happy to be relieved of dishing out passes.</p>
                    <p>Following the success of the system this year, Flagfinders will now be using it to manage their local authority student passes. Lesley and her team are relieved to finally get rid of their dreaded ticket printing machine!</p>
                    <p>“ShuttleID makes Flagfinders more efficient  and certainly raises our profile,” said Trish.</p>

                    {/* <div className='case-study__quote'>
                      <p>"Before, students were always losing their bus tickets, but they never seem to lose their phones! It has been much, <strong>much</strong> simpler."</p>
                      <p className='case-study__quote-author'>&mdash; Rebecca King, Student Welfare Office, Plumpton College</p>
                    </div>

                    <p>Rebecca has also found the level of support from ShuttleID incredibly responsive. Rebecca said, “I remember emailing one weekend, and I got a response and a change on the website there and then.”</p>

                    <h2>Putting Plumpton in charge</h2>
                    <p>Since going live, Rebecca has used the journey logs to uncover travel patterns, which help her decide if further transport is needed on busy days, making sure nobody is left out in the cold.</p>
                    <p>In fact, she’s realised ShuttleID does a lot more than confirming she has the right passenger numbers; the system is paying dividends regarding safeguarding and behaviour issues. Recently, for example, when a student reported being bullied on the bus, but they didn't know who it was, Plumpton simply sifted through the passenger list and photos to identify the troublemaker. </p>

                    <h2>Clearer communication</h2>
                    <p>The SMS service text alerts are an added feature that helping with safeguarding. Rebecca recalls an emergency where she needed to get an important message out and required the details of those onboard a bus. Now she has that information at her fingertips and can send messages instantly. She said, “To have these features makes me feel much more in control.”</p>
                    <p>Overall, Plumpton College estimates that using ShuttleID has saved the cost of one extra full-time member of staff. Importantly, Rebecca now has much more time to focus on her role as Student Welfare Officer, rather than as the sorter of bus tickets!</p> */}


                    {/* <div className='case-study__profile-quote'>
                      <div className='case-study__profile-quote__img'>
                        <img src={imgProfileKevinMortlock} alt="Ceri Taylor" />
                      </div>
                      <div className='case-study__profile-quote__quote'>
                        <p>"Recently, I was on holiday and, as usual, I took my laptop with me. Every other day I went on my ShuttleID portal to see how many tickets I’d sold and how close I was to my new targets. Compare that with two years ago? What a difference! With the customer numbers we’re doing now, the old system would have been Bedlam. This holiday I didn’t have to contact any parents or help set up standing orders. All I had to do was watch the numbers creep up. I’m now exceeding my targets, so it has definitely been a game changer."</p>
                        <p className='case-study__profile-quote__author'>Kevin Mortlock, Managing Director, Keane Travel Ltd</p>
                      </div>
                    </div> */}

                  </div>
                </div>
              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage
